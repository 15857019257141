import { createRouter, createWebHistory } from 'vue-router'
import { uzr } from '@/user/User';

let loguser = uzr.currentUser()
var perm = {}

if (loguser) {
    perm = loguser.perm
    perm
}

const routes = [
    {
        path: '/',
        redirect: 'dashboard',
        component: () => import('@/layouts/DashboardLayout.vue'),
        meta: { requiresAuth: true, },
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('@/views/dashBoard.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/userprofile',
            name: 'user-profile',
            component: () => import('@/views/UserProfile.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/staffs',
            name: 'staffs',
            component: () => import('@/views/hyzadmin/staff/staffList.vue'),
            meta: { has_perm: perm.c_v_h_staff },
        },
        {
            path: '/staffs/add',
            name: 'new-staffs',
            component: () => import('@/views/hyzadmin/staff/staffAdd.vue'),
            meta: { has_perm: perm.c_a_h_staff },
        },
        {
            path: '/staffs/edit/:staffid',
            name: 'edit-staff',
            component: () => import('@/views/hyzadmin/staff/staffEdit.vue'),
            meta: { has_perm: perm.c_e_h_staff },
        },
        {
            path: '/staffs/view/:staffid',
            name: 'view-staff',
            component: () => import('@/views/hyzadmin/staff/viewStaff.vue'),
            meta: { has_perm: perm.c_v_h_staff },
        },
        {
            path: '/deities',
            name: 'deities-list',
            component: () => import('@/views/templeadmin/deity/deitiesList.vue'),
            meta: { has_perm: perm.c_v_dty },
        },
        {
            path: '/deity/add',
            name: 'new-deity',
            component: () => import('@/views/templeadmin/deity/addDeity.vue'),
            meta: { has_perm: perm.c_a_dty },
        },
        {
            path: '/deity/view/:deityid',
            name: 'deity-view',
            component: () => import('@/views/templeadmin/deity/viewDeity.vue'),
            meta: { has_perm: perm.c_v_dty },
        },
        {
            path: '/deity/edit/:deityid',
            name: 'deity-edit',
            component: () => import('@/views/templeadmin/deity/editDeity.vue'),
            meta: { has_perm: perm.c_e_dty },
        },
        {
            path: '/festival/add',
            name: 'new-festival',
            component: () => import('@/views/templeadmin/festival/addFestival.vue'),
            meta: { has_perm: perm.c_v_fest },
        },
        {
            path: '/festivals',
            name: 'festivals-list',
            component: () => import('@/views/templeadmin/festival/listFestivals.vue'),
            meta: { has_perm: perm.c_v_fest },
        },
        {
            path: '/festival/view/:fest_id',
            name: 'festival-view',
            component: () => import('@/views/templeadmin/festival/viewFestival.vue'),
            meta: { has_perm: perm.c_v_fest },
        },
        {
            path: '/festival/edit/:fest_id',
            name: 'festival-edit',
            component: () => import('@/views/templeadmin/festival/editFestival.vue'),
            meta: { has_perm: perm.c_e_fest },
        },
        {
            path: '/deity/vazhipad',
            name: 'deity-vazhipad',
            component: () => import('@/views/templeadmin/deity/deityVazhipad.vue'),
            meta: { has_perm: perm.c_a_vc },
        },

        {
            path: '/deity/vazhipads',
            name: 'deitys-vazhipads',
            component: () => import('@/views/templeadmin/deity/deitysVazhipads.vue'),
            meta: { has_perm: perm.c_v_vc },
        },

        {
            path: '/deity/vazhipad/view/:dv_id',
            name: 'deity-vazhipad-view',
            component: () => import('@/views/templeadmin/deity/viewDeityVazhipad.vue'),
            meta: { has_perm: perm.c_v_vc },
        },

        {
            path: '/deity/vazhipad/edit/:dv_id',
            name: 'deity-vazhipad-edit',
            component: () => import('@/views/templeadmin/deity/editDeityVazhipad.vue'),
            meta: { has_perm: perm.c_e_vc },
        },

        {
            path: '/vazhipadcategories',
            name: 'vazhip-categ-list',
            component: () => import('@/views/templeadmin/vazhipad/vazhipCategList.vue'),
            meta: { has_perm: perm.c_v_vc },
        },
        {
            path: '/vazhipads',
            name: 'vazhipad-list', 
            component: () => import('@/views/templeadmin/vazhipad/vazhipadList.vue'),
            meta: { has_perm: perm.c_v_vc },
        },

        {
            path: 'temple/administration/templeinfo',
            name: 'temple-info',
            component: () => import('@/views/templeadmin/administration/templeInfo.vue'),
            meta: { has_perm: perm.c_v_temple_info },
        },

        {
            path: '/templeadminrole/add',
            name: 'new-temples-adm-role',
            component: () => import('@/views/templeadmin/adminusers/addRoles.vue'),
            meta: { has_perm: perm.c_a_role },
        },

        {
            path: '/templeadminrole/list',
            name: 'list-temples-adm-role',
            component: () => import('@/views/templeadmin/adminusers/listRoles.vue'),
            meta: { has_perm:perm.c_v_role },
        },

        {
            path: '/templeadminrole/edit/:roleid',
            name: 'edit-temple-role',
            component: () => import('@/views/templeadmin/adminusers/editRoles.vue'),
            meta: { has_perm: perm.c_e_role },
        },

        {
            path: '/templeadminuser/add',
            name: 'new-temples-adm',
            component: () => import('@/views/templeadmin/adminusers/addAdmin.vue'),
            meta: { has_perm: perm.c_a_admin },
        },

        {
            path: '/templeadminusers/list',
            name: 'manage-temp-admins',
            component: () => import('@/views/templeadmin/adminusers/listAdminUsers.vue'),
            meta: { has_perm: perm.c_v_admin },
        },

        {
            path: '/temples',
            name: 'temples',
            component: () => import('@/views/hyzadmin/temple/templesList.vue'),
            meta: { has_perm: perm.c_v_temple },
        },
        {
            path: '/temples/add',
            name: 'new-temples',
            component: () => import('@/views/hyzadmin/temple/templeAdd.vue'),
            meta: { has_perm: perm.c_a_temple },
        },
        {
            path: '/temple/view/:templeid',
            name: 'view-temple',
            component: () => import('@/views/hyzadmin/temple/templeView.vue'),
            meta: { has_perm: perm.c_v_temple },
        },
        {
            path: '/temple/edit/:templeid',
            name: 'edit-temple',
            component: () => import('@/views/hyzadmin/temple/templeEdit.vue'),
            meta: { has_perm: perm.c_e_temple },
        },
        
        {
            path: '/hyzroles',
            name: 'hyzroles',
            component: () => import('@/views/hyzadmin/roles/roleList.vue'),
            meta: { has_perm: perm.c_v_h_role },
        },
        {
            path: '/hyzroles/add',
            name: 'new-hyzrole',
            component: () => import('@/views/hyzadmin/roles/roleAdd.vue'),
            meta: { has_perm: perm.c_a_h_role },
        },
        {
            path: '/hyzroles/edit/:roleid',
            name: 'edit-hyz-role',
            component: () => import('@/views/hyzadmin/roles/roleEdit.vue'),
            meta: { has_perm: perm.c_e_h_role },
        },
       
        {
            path: '/templeadmins',
            name: 'temple-admins',
            component: () => import('@/views/hyzadmin/temple/templeAdminList.vue'),
            meta: { has_perm: perm.c_v_temple_adm },
        },
        
        {
            path: '/templeadmin/edit/:templeadmid',
            name: 'edit-temple-admin',
            component: () => import('@/views/hyzadmin/temple/editTempleAdmin.vue'),
            meta: { has_perm: perm.c_e_temple_adm },
        },
        
        // {
        //     path: '/masterdata/dashboard',
        //     name: 'md-menupage',
        //     component: () => import('@/views/menupages/masterData.vue'),
        //     meta: { has_perm: perm.c_m_md },
        // },
        {
            path: 'hyz/masterdata/masterdeities',
            name: 'master-deities',
            component: () => import('@/views/hyzadmin/masterdata/masterDeities.vue'),
            meta: { has_perm: perm.c_m_md },
        },
        {
            path: 'hyz/masterdata/addmasterdeity',
            name: 'new-master-deity',
            component: () => import('@/views/hyzadmin/masterdata/addMasterDeity.vue'),
            meta: { has_perm: perm.c_m_md },
        },
        {
            path: '/hyz/masterdata/:mdeityid',
            name: 'master-deity-edit',
            component: () => import('@/views/hyzadmin/masterdata/editMasterDeity.vue'),
            meta: { has_perm: perm.c_m_md },
        },
        {
            path: '/vazhipad/orders',
            name: 'vazhipad-orders',
            component: () => import('@/views/templeadmin/vazhipad/vazhipadOrder.vue'),
            meta: { has_perm: perm.c_v_vo },
        },
        {
            path: '/vazhipad/orders/viewdetails/:order_id?',
            name: 'order-details',
            component: () => import('@/views/templeadmin/vazhipad/orderDetails.vue'),
            meta: { has_perm: perm.c_v_vo },
        },
        {
            path: '/donations',
            name: 'donations-list',
            component: () => import('@/views/templeadmin/donations/donationsList.vue'),
            meta: { has_perm: perm.c_v_do },
        },
        {
            path: '/donations/view/:donationid',
            name: 'view-donation',
            component: () => import('@/views/templeadmin/donations/viewDonation.vue'),
            meta: { has_perm: perm.c_v_do },
        },
        {
            path: '/family/add',
            name: 'add-family',
            component: () => import('@/views/templeadmin/family/addFamily.vue'),
            meta: { has_perm: perm.c_a_fam },
        },
        {
            path: '/family/list',
            name: 'list-family',
            component: () => import('@/views/templeadmin/family/listFamily.vue'),
            meta: { has_perm: perm.c_v_fam },
        },
        {
            path: '/family/view/:familyid',
            name: 'view-family',
            component: () => import('@/views/templeadmin/family/viewFamily.vue'),
            meta: { has_perm: perm.c_v_fam },
        },
        {
            path: '/family/edit/:familyid',
            name: 'edit-family',
            component: () => import('@/views/templeadmin/family/editFamily.vue'),
            meta: { has_perm: perm.c_e_fam },
        },
        {
            path: '/family/member',
            name: 'add-family-members',
            component: () => import('@/views/templeadmin/family/addMember.vue'),
            meta: { has_perm: perm.c_a_fam },
        },
        {
            path: '/members/list',
            name: 'list-members',
            component: () => import('@/views/templeadmin/family/listMember.vue'),
            meta: { has_perm: perm.c_v_fam },
        },
        {
            path: '/member/edit/:memberid',
            name: 'edit-member',
            component: () => import('@/views/templeadmin/family/editMember.vue'),
            meta: { has_perm: perm.c_e_fam },
        },
        {
            path: '/report/vazhipad',
            name: 'vazhipad-report',
            component: () => import('@/views/templeadmin/report/vazhipadReport.vue'),
            meta: { has_perm: perm.c_m_exp },
        },
        
        ]
    },
    {
        path: '/auth',
        name: 'Auth',
        component: () =>
            import('@/layouts/AuthenticationLayout.vue'),
        meta: {
            requiresAuth: false,
            has_perm: true,
        },
        children: [{
            path: '/login/',
            name: 'login',
            component: () => import('@/views/auth/LoginPage.vue')
        },
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/NotFound.vue"),
        meta: { has_perm: true, },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = ['/login'];
    const authRequired = to.matched.some(record => record.meta.requiresAuth)
    const loggedIn = localStorage.getItem('templeuser');
    const hasPermission = to.matched.some(record => record.meta.has_perm)

    //console.log(authRequired);
    if (authRequired && !loggedIn) {
        const path = ''
        next('/login' + path);
    }else if (!hasPermission) {
        return next('/not-found');
    } else {
        next()
    }
})

export default router