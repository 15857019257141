import { createStore } from 'vuex'

const user = localStorage.getItem('templeuser');
const initialState = user ? true : false
let initialUserData ={}
if(user){
   initialUserData = JSON.parse(user);
}

export default createStore({
  state: {
    'userLoggedin': initialState,
    'userData': initialUserData
  },
  getters: {
  },
  mutations: {
    logOutUser: state => {
      state.userLoggedin = initialState
    },
    logInUser: state => {
      state.userLoggedin = true
    },
    changeUserData:(state,payload) => {
      state.userData = payload
    }
  },
  actions: {
    logOutUser: context => {
      context.commit('logOutUser')
    },
    logInUser: context => {
      context.commit('logInUser')
    },
    changeUserData:(context,payload) => {
      context.commit('changeUserData',payload)
    }
  },
  modules: {
  }
})
